import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from '../common.slice';

const initialState = {
  orderProductLoading: false,
  gholdPeriodData: {},
  getPostedNotesData: {},
  productsListData: [],
  paymenrBalanceData: {},
  getInvoiceData: {},
  placesTempData: {},
  validProductData: [],
};

export const getHoldPeriod = createAsyncThunk(
  'admin/get-hold-period',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/booking/${_id}/hold_period`)
        .then(res => {
          if (res.data.err === 0) {
            if (res.data.data) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const getPostedNotes = createAsyncThunk(
  'admin/get-posted-notes-ac',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/booking/${_id}/posted_notes`)
        .then(res => {
          if (res.data.err === 0) {
            if (res.data.data) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const createInvoice = createAsyncThunk(
  'admin/create-invoice-as',
  (invoice, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('admin/invoice/create', invoice)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const createProducts = createAsyncThunk(
  'admin/create-product-assd',
  (products, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('admin/products', products)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const productsList = createAsyncThunk(
  'admin/product-list-tr',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/products_list', _id)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res?.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const productsForDateApi = createAsyncThunk(
  'admin/products-for-date-sg',
  (products, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/products_for_date', products)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const placesTempApi = createAsyncThunk(
  'admin/places-temp-api-ggh',
  (props, { dispatch }) => {
    const { data, _id } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/places_temp`, data)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const priceRulesApi = createAsyncThunk(
  'admin/price-rule-api',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/price_rules')
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const paymentBalance = createAsyncThunk(
  'admin/payment-balance-dfg',
  (props, { dispatch }) => {
    const { data, _id } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/balance`, data)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          if (Object.values(error.response.data.data).length) {
            dispatch(
              showMessage({
                message: `${Object.values(error.response.data.data).join(
                  '\n',
                )}`,
              }),
            );
          } else dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const getInvoiceBooking = createAsyncThunk(
  'admin/get-invoice-booking-d',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/get_invoice/${_id}`)
        .then(res => {
          if (res.data.err === 0) {
            if (res.data?.data) {
              resolve(res.data?.data);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const apiValidProduct = createAsyncThunk(
  'admin/api-valid-product',
  (props, { dispatch, getState }) => {
    const state = getState();
    const { orderProduct } = state;
    const { validProductData } = orderProduct;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/valid_product`, props)
        .then(res => {
          if (res.data.err === 0) {
            let newArray = [];
            const oldData = validProductData;
            if (oldData?.length > 0) {
              if (
                oldData?.find(
                  item =>
                    item.product_id === props.product_id &&
                    item.booking_id === props.booking_id,
                )
              ) {
                newArray = oldData?.map(item => {
                  if (
                    item.product_id === props.product_id &&
                    item.booking_id === props.booking_id
                  ) {
                    return { ...props, isvalid: res.data.data };
                  }
                  return item;
                });
              } else {
                newArray = [...oldData, { ...props, isvalid: res.data.data }];
              }
            } else {
              newArray = [{ ...props, isvalid: res.data.data }];
            }
            resolve(newArray);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const orderProductSlice = createSlice({
  name: 'orderProduct',
  initialState,
  reducers: {
    setOrderProductLoading: (state, action) => {
      state.orderProductLoading = action.payload;
    },
    setIsOrderProductUpdated: (state, action) => {
      state.isOrderProductUpdated = action.payload;
    },
  },
  extraReducers: {
    [getHoldPeriod.pending]: state => {
      state.orderProductLoading = true;
    },
    [getHoldPeriod.rejected]: state => {
      state.gholdPeriodData = {};
      state.orderProductLoading = false;
    },
    [getHoldPeriod.fulfilled]: (state, action) => {
      state.gholdPeriodData = action.payload;
      state.orderProductLoading = false;
    },
    [getPostedNotes.pending]: state => {
      state.orderProductLoading = true;
    },
    [getPostedNotes.rejected]: state => {
      state.getPostedNotesData = {};
      state.orderProductLoading = false;
    },
    [getPostedNotes.fulfilled]: (state, action) => {
      state.getPostedNotesData = action.payload;
      state.orderProductLoading = false;
    },
    [createInvoice.pending]: state => {
      state.isOrderProductUpdated = false;
      state.orderProductLoading = true;
    },
    [createInvoice.rejected]: state => {
      state.isOrderProductUpdated = false;
      state.orderProductLoading = false;
    },
    [createInvoice.fulfilled]: (state, action) => {
      state.isOrderProductUpdated = true;
      state.orderProductLoading = false;
    },
    [createProducts.pending]: state => {
      // state.isOrderProductUpdated = false;
      state.orderProductLoading = true;
    },
    [createProducts.rejected]: state => {
      // state.isOrderProductUpdated = false;
      state.orderProductLoading = false;
    },
    [createProducts.fulfilled]: (state, action) => {
      // state.isOrderProductUpdated = true;
      state.orderProductLoading = false;
    },
    [productsList.pending]: state => {
      state.orderProductLoading = true;
    },
    [productsList.rejected]: state => {
      state.productsListData = [];
      state.orderProductLoading = false;
    },
    [productsList.fulfilled]: (state, action) => {
      state.productsListData = action.payload;
      state.orderProductLoading = false;
    },
    [productsForDateApi.pending]: state => {
      state.orderProductLoading = true;
    },
    [productsForDateApi.rejected]: state => {
      // state.productsListData = [];
      state.orderProductLoading = false;
    },
    [productsForDateApi.fulfilled]: (state, action) => {
      // state.productsListData = action.payload;
      state.orderProductLoading = false;
    },
    [placesTempApi.pending]: state => {
      state.orderProductLoading = true;
    },
    [placesTempApi.rejected]: state => {
      state.placesTempData = [];
      state.orderProductLoading = false;
    },
    [placesTempApi.fulfilled]: (state, action) => {
      state.placesTempData = action.payload;
      state.orderProductLoading = false;
    },
    [priceRulesApi.pending]: state => {
      state.orderProductLoading = true;
    },
    [priceRulesApi.rejected]: state => {
      // state.productsListData = [];
      state.orderProductLoading = false;
    },
    [priceRulesApi.fulfilled]: (state, action) => {
      // state.productsListData = action.payload;
      state.orderProductLoading = false;
    },
    [paymentBalance.pending]: state => {
      state.orderProductLoading = true;
    },
    [paymentBalance.rejected]: state => {
      state.paymenrBalanceData = {};
      state.orderProductLoading = false;
    },
    [paymentBalance.fulfilled]: (state, action) => {
      state.paymenrBalanceData = action.payload;
      state.orderProductLoading = false;
    },
    [getInvoiceBooking.pending]: state => {
      state.orderProductLoading = true;
    },
    [getInvoiceBooking.rejected]: state => {
      state.getInvoiceData = {};
      state.orderProductLoading = false;
    },
    [getInvoiceBooking.fulfilled]: (state, action) => {
      state.getInvoiceData = action.payload;
      state.orderProductLoading = false;
    },

    [apiValidProduct.pending]: state => {
      state.orderProductLoading = true;
    },
    [apiValidProduct.rejected]: state => {
      state.validProductData = [];
      state.orderProductLoading = false;
    },
    [apiValidProduct.fulfilled]: (state, action) => {
      state.validProductData = action.payload;
      state.orderProductLoading = false;
    },
  },
});

export const { setOrderProductLoading, setIsOrderProductUpdated } =
  orderProductSlice.actions;

export default orderProductSlice.reducer;
