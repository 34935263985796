import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from '../common.slice';
const initialState = {
  bookingList: [],
  bookingLoading: false,
  isBookingUpdated: false,
  // isMakePayment: false,
  bookingDetail: {},
  payByBankDetail: {},
  bookingDuplicate: {},
  opayoUrlforNav: {},
  isConfirmPlaceForPayment: false,
  isConfirmPlaceForNoPayment: false,
  bookingBalanceState: {},
  apiEticketPrintalleticketsData: {},
  productTotalData: {},
  productListData: [],
};

export const getBookingListData = createAsyncThunk(
  'admin/get-booking-list',
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('api/booking/list')
        .then(res => {
          if (res.data.err === 0) {
            if (res.data.data.length > 0) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const getGuestBookingListData = createAsyncThunk(
  'admin/get-guest-booking-list',
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/booking_list', data)
        .then(res => {
          if (res.data.err === 0) {
            if (res.data.data.length > 0) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);
export const getBookingData = createAsyncThunk(
  'admin/get-booking',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/booking/${_id}`)
        .then(res => {
          if (res.data.err === 0) {
            if (Object.keys(res.data.data).length > 0) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);
export const createBooking = createAsyncThunk(
  'admin/create-booking',
  (booking, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/booking/create', booking)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);
export const updateBooking = createAsyncThunk(
  'admin/update-booking',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { _id, booking } = props;
      axios
        .post(`api/booking/${_id}`, booking)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const paymentConfirmPlace = createAsyncThunk(
  'admin/payment-confirm-place',
  (props, { dispatch }) => {
    const { data, _id } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/confirm`, data)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const paymentConfirmNoPay = createAsyncThunk(
  'admin/payment-confirm-no-pay',
  (props, { dispatch }) => {
    const { data, _id } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/confirm_no_pay`, data)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const productsTotalApi = createAsyncThunk(
  'admin/products-total-api-data-as',
  (product, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/products_total', product)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

// export const makePayment = createAsyncThunk(
//   'admin/make-payment',
//   (booking, { dispatch }) => {
//     return new Promise((resolve, reject) => {
//       axios
//         .post('/api/make_payment', booking)
//         .then(res => {
//           if (res.data.err === 0) {
//             dispatch(
//               showMessage({ message: res.data.msg, varient: 'success' }),
//             );
//             resolve(res.data);
//           } else {
//             dispatch(showMessage({ message: res.data.msg }));
//             reject();
//           }
//         })
//         .catch(error => {
//           dispatch(showMessage({ message: error.response.data.msg }));
//           reject(error);
//         });
//     });
//   },
// );

export const getPayByBank = createAsyncThunk(
  'admin/get-pay-by-bank',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/confirmterms`)
        .then(res => {
          if (res.data.err === 0) {
            if (Object.keys(res.data.data).length > 0) {
              resolve(res.data.data[0]);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const apiBookingsDuplicate = createAsyncThunk(
  'admin/api-bookings-duplicate-check',
  (booking, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/bookings', booking)
        .then(res => {
          if (res.data.err === 0) {
            resolve(res?.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const apiBookingBalance = createAsyncThunk(
  'admin/api-bookings-balance-check',
  (props, { dispatch }) => {
    const { booking, _id } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/balance`, booking)
        .then(res => {
          if (res.data.err === 0) {
            resolve(res?.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const apiBookingPlacesTemp = createAsyncThunk(
  'admin/api-bookings-places-temp',
  (props, { dispatch }) => {
    const { booking, _id } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/places_temp`, booking)
        .then(res => {
          if (res.data.err === 0) {
            resolve(res?.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const apiEticketPrintalletickets = createAsyncThunk(
  'admin/api-eticket-print-all-etickets',
  (props, { dispatch }) => {
    const { booking } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/eticket/printalletickets`, booking)
        .then(res => {
          if (res.data.err === 0) {
            resolve(res?.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const getProductsEventApi = createAsyncThunk(
  'admin/get-products-event-api',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/products_event/${_id}`)
        .then(res => {
          if (res.data.err === 0) {
            if (Object.keys(res.data.data).length > 0) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingLoading: (state, action) => {
      state.bookingLoading = action.payload;
    },
    setIsBookingUpdated: (state, action) => {
      state.isBookingUpdated = action.payload;
    },
    setBookingDetail: (state, action) => {
      state.bookingDetail = action.payload;
    },
    setPayByBankDetail: (state, action) => {
      state.payByBankDetail = action.payload;
    },
    setIsConfirmPlaceForPayment: (state, action) => {
      state.isConfirmPlaceForPayment = action.payload;
    },
    setapiEticketPrintalleticketsData: (state, action) => {
      state.apiEticketPrintalleticketsData = action.payload;
    },
  },
  extraReducers: {
    [getBookingListData.pending]: state => {
      state.bookingLoading = true;
    },
    [getBookingListData.rejected]: state => {
      state.bookingList = [];
      state.bookingLoading = false;
    },
    [getBookingListData.fulfilled]: (state, action) => {
      state.bookingList = action.payload;
      state.bookingLoading = false;
    },
    [getGuestBookingListData.pending]: state => {
      state.bookingLoading = true;
    },
    [getGuestBookingListData.rejected]: state => {
      state.bookingList = [];
      state.bookingLoading = false;
    },
    [getGuestBookingListData.fulfilled]: (state, action) => {
      state.bookingList = action.payload;
      state.bookingLoading = false;
    },
    [getBookingData.pending]: state => {
      state.bookingLoading = true;
      state.bookingDetail = {};
    },
    [getBookingData.rejected]: state => {
      state.bookingLoading = false;
      state.bookingDetail = {};
    },
    [getBookingData.fulfilled]: (state, action) => {
      state.bookingLoading = false;
      state.bookingDetail = action.payload;
    },
    [createBooking.pending]: state => {
      state.isBookingUpdated = false;
      state.bookingLoading = true;
    },
    [createBooking.rejected]: state => {
      state.isBookingUpdated = false;
      state.bookingLoading = false;
    },
    [createBooking.fulfilled]: (state, action) => {
      state.isBookingUpdated = true;
      state.bookingLoading = false;
    },
    [updateBooking.pending]: state => {
      state.isBookingUpdated = false;
      state.bookingLoading = true;
    },
    [updateBooking.rejected]: state => {
      state.isBookingUpdated = false;
      state.bookingLoading = false;
    },
    [updateBooking.fulfilled]: (state, action) => {
      state.isBookingUpdated = true;
      state.bookingLoading = false;
    },
    // [makePayment.pending]: state => {
    //   state.isMakePayment = false;
    //   state.bookingLoading = true;
    // },
    // [makePayment.rejected]: state => {
    //   state.isMakePayment = false;
    //   state.bookingLoading = false;
    // },
    // [makePayment.fulfilled]: (state, action) => {
    //   state.isMakePayment = true;
    //   state.bookingLoading = false;
    // },
    [paymentConfirmPlace.pending]: state => {
      state.isConfirmPlaceForPayment = false;
      state.opayoUrlforNav = {};
      state.bookingLoading = true;
    },
    [paymentConfirmPlace.rejected]: state => {
      state.opayoUrlforNav = {};
      state.isConfirmPlaceForPayment = false;
      state.bookingLoading = false;
    },
    [paymentConfirmPlace.fulfilled]: (state, action) => {
      state.opayoUrlforNav = action.payload;
      state.isConfirmPlaceForPayment = true;
      state.bookingLoading = false;
    },

    [paymentConfirmNoPay.pending]: state => {
      state.isConfirmPlaceForNoPayment = false;
      state.bookingLoading = true;
    },
    [paymentConfirmNoPay.rejected]: state => {
      state.isConfirmPlaceForNoPayment = false;
      state.bookingLoading = false;
    },
    [paymentConfirmNoPay.fulfilled]: state => {
      state.isConfirmPlaceForNoPayment = true;
      state.bookingLoading = false;
    },

    [getPayByBank.pending]: state => {
      state.bookingLoading = true;
      state.payByBankDetail = {};
    },
    [getPayByBank.rejected]: state => {
      state.bookingLoading = false;
      state.payByBankDetail = {};
    },
    [getPayByBank.fulfilled]: (state, action) => {
      state.bookingLoading = false;
      state.payByBankDetail = action.payload;
    },
    [apiBookingsDuplicate.pending]: state => {
      state.bookingDuplicate = {};
      state.bookingLoading = true;
    },
    [apiBookingsDuplicate.rejected]: state => {
      state.bookingLoading = false;
      state.bookingDuplicate = {};
    },
    [apiBookingsDuplicate.fulfilled]: (state, action) => {
      state.bookingLoading = false;
      state.bookingDuplicate = action.payload;
    },
    [apiBookingBalance.pending]: state => {
      // state.bookingDuplicate = {};
      state.bookingLoading = true;
    },
    [apiBookingBalance.rejected]: state => {
      state.bookingLoading = false;
      // state.bookingDuplicate = {};
    },
    [apiBookingBalance.fulfilled]: (state, action) => {
      state.bookingLoading = false;
      // state.bookingDuplicate = action.payload;
    },
    [apiBookingPlacesTemp.pending]: state => {
      // state.bookingDuplicate = {};
      state.bookingLoading = true;
    },
    [apiBookingPlacesTemp.rejected]: state => {
      state.bookingLoading = false;
      // state.bookingDuplicate = {};
    },
    [apiBookingPlacesTemp.fulfilled]: (state, action) => {
      state.bookingLoading = false;
      // state.bookingDuplicate = action.payload;
    },
    [apiEticketPrintalletickets.pending]: state => {
      state.apiEticketPrintalleticketsData = {};
      state.bookingLoading = true;
    },
    [apiEticketPrintalletickets.rejected]: state => {
      state.bookingLoading = false;
      state.apiEticketPrintalleticketsData = {};
    },
    [apiEticketPrintalletickets.fulfilled]: (state, action) => {
      state.bookingLoading = false;
      state.apiEticketPrintalleticketsData = action.payload;
    },
    [productsTotalApi.pending]: state => {
      state.bookingLoading = true;
    },
    [productsTotalApi.rejected]: state => {
      state.bookingLoading = false;
      state.productTotalData = {};
    },
    [productsTotalApi.fulfilled]: (state, action) => {
      state.bookingLoading = false;
      state.productTotalData = action.payload;
    },
    // product list api data on drink
    [getProductsEventApi.pending]: state => {
      state.bookingLoading = true;
    },
    [getProductsEventApi.rejected]: state => {
      state.bookingLoading = false;
      state.productListData = [];
    },
    [getProductsEventApi.fulfilled]: (state, action) => {
      state.bookingLoading = false;
      state.productListData = action.payload;
    },
  },
});

export const {
  setBookingLoading,
  setIsBookingUpdated,
  setBookingDetail,
  // setIsMakePayment,
  setPayByBankDetail,
  setIsConfirmPlaceForPayment,
  setapiEticketPrintalleticketsData,
} = bookingSlice.actions;

export default bookingSlice.reducer;
