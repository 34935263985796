import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showMessage } from './common.slice';
import axios from 'axios';

const initialState = {
  googlePlaceLoading: false,
  isGooglePlaceUpdated: false,
  googlePlaceDetail: {},
};

export const getGooglePlaceListData = createAsyncThunk(
  'admin-google-place-list-data',
  (postcode, { dispatch }) => {
    // const apiKey = 'AIzaSyCcsYYHq6kCYGzx8BlUp0gR12iwSN9xQeM';

    return new Promise((resolve, reject) => {
      axios
        .post(`admin/lookup`, postcode)
        .then(res => {
          if (res.data.err === 0) {
            resolve(res.data);
          } else {
            // if (postcode === '') {
            //   dispatch(
            //     showMessage({ message: 'Please enter postcode for lookup' }),
            //   );
            // }
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          // if (postcode === '') {
          //   dispatch(
          //     showMessage({ message: 'Please enter postcode for lookup' }),
          //   );
          // }
          reject(error);
        });
    });
  },
);

export const googlePlaceSlice = createSlice({
  name: 'googlePlace',
  initialState,
  reducers: {
    setIsGooglePlaceUpdated: (state, action) => {
      state.isGooglePlaceUpdated = action.payload;
    },
    setGooglePlaceDetail: (state, action) => {
      state.googlePlaceDetail = action.payload;
    },
  },
  extraReducers: {
    [getGooglePlaceListData.pending]: state => {
      state.isGooglePlaceUpdated = false;
      state.googlePlaceLoading = true;
    },
    [getGooglePlaceListData.rejected]: state => {
      state.googlePlaceLoading = false;
      state.isGooglePlaceUpdated = false;
      state.googlePlaceDetail = [];
    },
    [getGooglePlaceListData.fulfilled]: (state, action) => {
      state.googlePlaceLoading = false;
      state.googlePlaceDetail = action.payload;
      state.isGooglePlaceUpdated = true;
    },
  },
});
export const { setIsGooglePlaceUpdated, setGooglePlaceDetail } =
  googlePlaceSlice.actions;
export default googlePlaceSlice.reducer;
