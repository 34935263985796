import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Routes from 'routes/index';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import GetCookies from 'hooks/GetCookies';
import { setIsUserLogin } from 'store/reducers/auths.slice';
import RemoveCookies from 'hooks/RemoveCookies';
import SetCookies from 'hooks/SetCookies';
import { getVenueListData } from 'store/reducers/Venue/venue.slice';
import { getAboutListData } from 'store/reducers/About/about.slice';
import { showMessage } from 'store/reducers/common.slice';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const values = GetCookies('Token');
    if (values) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${atob(values)}`;
      dispatch(setIsUserLogin(true));
      RemoveCookies('Token');
      SetCookies('Token', btoa(atob(values)));
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    console.log('useEffect call');
    const interceptor = axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        console.log('error in useEffect ', error.response);
        if (error.response.status === 401) {
          console.log('status is 401');
          RemoveCookies('UserSession');
          RemoveCookies('Token');
          dispatch(setIsUserLogin(false));
          setTimeout(() => {
            dispatch(
              showMessage({
                message: 'Logged out Successfully',
                varient: 'success',
              }),
            );
            console.log('navigate to login');
            console.log('URL------------->', window.location.pathname);
            navigate('/login');
          }, 2000);
        }
        return Promise.reject(error);
      },
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(getVenueListData());
    dispatch(getAboutListData());
  }, [dispatch]);
  return (
    <>
      <Helmet
        titleTemplate="%s - Spectacular Christmas Parties 2024 - Xmas Parties from Best Parties Ever"
        defaultTitle="Spectacular Christmas Parties 2024 - Xmas Parties from Best Parties Ever"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <Routes />
    </>
  );
}
